  export const formatDateTime = (isoDate) => {
    if (isoDate) {
      var date = new Date(isoDate);
      var fullDate = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
      var hours = addZeros(date.getHours());
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      var fullTime = hours + ":" + addZeros(date.getMinutes());
      return fullDate + ' ' + fullTime + ' ' + ampm;
    }
  }

  const addZeros = (date) => {
    return ("0" + date).slice(-2);
  }