import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getApplicationList = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/Application', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateApplication = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/Application', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createApplication = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/Application', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const getApplicationOptionList = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/ApplicationOption', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createApplicationOption = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/ApplicationOption', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateApplicationOption = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/ApplicationOption', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}