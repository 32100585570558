import React from "react";
import { b2cPolicies } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

export default function User() {

  let user = JSON.parse(sessionStorage.getItem("user"));
  const { instance, inProgress } = useMsal();

  const handleProfileEdit = () => {
    if (inProgress === InteractionStatus.None) {
        instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
    }
  };
  
  return (
    <div>
      <div className="mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h2 className="text-gray-900 text-3xl font-extrabold tracking-tight mt-2">My Profile</h2>
          <div className="py-4 grid gap-4">
            <div>
              <strong>Username: </strong>{user?.email}
            </div>
          </div>
          <button
            type="button"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
            onClick={handleProfileEdit}>
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-gray-200 group-hover:text-gray-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                </svg>
            </span>
            Edit Profile
        </button>
        </div>
      </div>
    </div>
  )
}