import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getLicenseList = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/License', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateLicense = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/License', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createLicense = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/License', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const getLicenseOptionList = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/LicenseOption', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateLicenseOption = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/LicenseOption', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createLicenseOption = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/LicenseOption', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const deleteLicenseOption = async (id) => {
  const config = {
    withCredentials: true
  };
  
  try {
    const response = await axios.delete(`/LicenseOption/ById/${id}`, {}, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const resetLicense = async (licenseId) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put(`/License/Reset/${licenseId}`, {}, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}