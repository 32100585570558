import React from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { loginRequest } from './authConfig';

const RequestInterceptor = ({children}) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const company = JSON.parse(sessionStorage.getItem("company"));

    axios.interceptors.request.use(async (config) => {
        if (!account){
            throw Error('No active account. Verify a user has been signed in');
        }

        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account
        });
        const bearer = `Bearer ${response.accessToken}`;
        config.headers.Authorization = bearer;

        if (company) {
            config.headers['company'] = company.id;
        }

        return config;
    });

    return (
        <>
            {children}
        </>
    );
};

RequestInterceptor.propTypes = {
    children: PropTypes.object
  }

export default RequestInterceptor;

RequestInterceptor.propTypes = {
    company: PropTypes.string
  }