import React from 'react';
import cloud9Logo from '../images/cloud9fx_icon.webp';


export default function NotFound() {
  return (
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <img className="mx-auto h-16 w-auto" src={cloud9Logo} alt="Logo" />
        </div>
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-2xl font-semibold text-emerald-500 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-3xl font-semibold text-gray-900 tracking-tight sm:text-5xl">This page does not exist.</h1>
            <p className="mt-4 text-lg text-gray-500">The page you are looking for could not be found.</p>
            <div className="mt-10 flex justify-center space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a href="/" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"> Go back home </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}