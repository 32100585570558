import React, { useState, useEffect } from "react";
import {
  getLicenseList,
  updateLicense,
  createLicense, 
  createLicenseOption,
  updateLicenseOption,
  deleteLicenseOption,
  resetLicense
} from '../services/Licenses';
import { getCompanyList } from '../services/Companies';
import { getApplicationList } from '../services/Applications';
import Loader from "../components/Loader";
import Modal from 'react-modal';

import CreateEditModal from "../components/CreateEditModal";
import { licenseKey, mobileLicenseKey } from "../utils/labelMap";
import { createLicensesForm, editLicensesForm, detailLicensesForm } from "../utils/forms";
import DynamicTable from "../components/DynamicTable";
import CheckboxModal from "../components/CheckboxModal"

export default function Licenses() {
  const [licenses, setLicenses] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [companies, setCompanies] = useState([]);
  const [applications, setApplications] = useState([]);
  const [registrationColumns, setLicenseColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingLicense, setEditingLicense] = useState(false);
  const [viewingLicenseDetail, setViewingDetailLicense] = useState(false);
  const [creatingLicense, setCreatingLicense] = useState(false);
  const [creatingLicenseOption, setCreatingLicenseOption] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [activeLicenseId, setActiveLicenseId] = useState(-1);
  const [activeApplicationId, setActiveApplicationId] = useState(-1);
  const [createFormWithSelectData, setCreateFormWithSelectData] = useState([]);
  const [modifyError, setModifyError] = useState(false);
  const [showResetLicenseModal, setShowResetLicenseModal] = useState(false);
  const [activeLicenseOptions, setActiveLicenseOptions] = useState([])
  const [inactiveLicenseOptions, setInactiveLicenseOptions] = useState([])
  const [loadingOption, setLoadingOption] = useState(false);

  useEffect(() => {
    async function fetchLicenses() {
        setLoading(true);
        const data = await getLicenseList();
        if (data.length > 0) {
          setLicenses(data);
          setLicenseColumns(Object.keys(data[0]));
        }
        setLoading(false);
    }
    fetchLicenses();

    async function fetchCompaniesAndApplications() {
      setLoading(true);
        const companies = await getCompanyList();
        const applications = await getApplicationList();
        if (companies.error || applications.error) {
          setModifyError(true);
        }
        if (companies.length > 0) {
          setCompanies(companies);
          addDataToSelectOptions(companies, 'companyIdName', 'name');
        }
        if (applications.length > 0) {
          setApplications(applications);
          addDataToSelectOptions(applications, 'applicationIdName', 'applicationId');
        }
        setLoading(false);
    }
    fetchCompaniesAndApplications();
  }, []);

  const addDataToSelectOptions = (data, key, value) => {
    let createFormCopy = [...createLicensesForm];
    const keyIndex = createFormCopy.findIndex(f => f.name === key);
    const keyOptions = data.map(c => c[value]);
    createFormCopy[keyIndex].options = keyOptions;
    setCreateFormWithSelectData(createFormCopy);
  }

  const canViewUserCount = (license) => {
    let application = applications.filter(p => p.id === license.applicationId)[0];
    return application.hasUserCount;
  }

  const canViewUserCountApplications = () => {
    let hasUserCount;
    if (selectedData.applicationId) {
      let application = applications.filter(p => p.id === selectedData.applicationId)[0];
      hasUserCount = application.hasUserCount;
    }
    if (hasUserCount) {
      return true;
    } else {
      delete selectedData['userCount'];
      return false;
    }
  }

  const openCreateLicenseModal = () => {
    setSelectedData({});
    setCreatingLicense(true);
  }

  const getOptionIdName = (application, licenseOption) => {
    let applicationOption = application.applicationOptions.filter(lo => lo.id === licenseOption.optionId)[0];
    return applicationOption.optionId;
  }

  const getOptionName = (application, licenseOption) => {
    let applicationOption = application.applicationOptions.filter(lo => lo.id === licenseOption.optionId)[0];
    return applicationOption.optionName;
  }

  const hasLicenseOptions = (license) => {
    return license.licenseOptions.length > 0;
  }

  const openCreateLicenseOptionModal = (licenseId) => {
    const license = licenses.filter(license => license.id === licenseId)[0];
    const application = applications.filter(p => p.id === license.applicationId)[0];
    setActiveLicenseId(license.id);
    setActiveApplicationId(application.id);
    if (!hasLicenseOptions) {
      return;
    }
    let activeOptions = license.licenseOptions.map((cro) => {
      return ({
        id: cro.id,
        optionIdName: getOptionIdName(application, cro),
        optionName: getOptionName(application, cro)
      })
    });
    let inactiveOptions = [];
    application.applicationOptions.forEach((po) => {
      let match = activeOptions.filter(ao => ao.optionIdName === po.optionId);
      if (match.length === 0) {
          inactiveOptions.push({optionIdName: po.optionId, optionName: po.optionName});
      }
    })
    setActiveLicenseOptions(activeOptions);
    setInactiveLicenseOptions(inactiveOptions);
    setCreatingLicenseOption(true);
  }

  const closeModal = () => {
    setEditingLicense(false);
    setViewingDetailLicense(false);
    setCreatingLicense(false);
    setCreatingLicenseOption(false);
    setActiveLicenseOptions([]);
    setInactiveLicenseOptions([]);
    setActiveLicenseId(-1);
    setActiveApplicationId(-1);
    setErrorMessage('');
  }

  const setDate = (value, name) => {

    let utcDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0);
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: utcDate
    }))
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const handleSelect = e => {
    let { name, value } = e.target;
    if (value === 'default') {
      let data = {...selectedData};
      let key;
      let field = e.target.field;
      if (name === 'companyIdName') {
        key = 'companyId';
        data['companyIdName'] = field;
      } else if (name === 'applicationIdName') {
        key = 'applicationId';
        data['applicationIdName'] = field;
      } else if (name === 'userCount') {
        key = 'userCount';
        data[key] = null;
      }
      delete data[key];
      setSelectedData(data);
      return;
    }
    if (name === "companyIdName") {
      const company = companies.filter(c => c.name === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        companyId: company[0].id
      }));
    } else if (name === "applicationIdName") {
      const application = applications.filter(c => c.applicationId === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        applicationId: application[0].id
      }));
    } else if (name === "userCount") {
      setSelectedData(selectedData => ({
        ...selectedData,
        userCount: value
      }))
    }
  }

  const createRemoveLicenseOption = async (checkboxOption, action) => {
    setErrorMessage("")
    setLoadingOption(true)
    if (action === "create") {
      let application = applications.filter(p => p.id === activeApplicationId)[0];
      let applicationOption = application.applicationOptions.filter(ao => ao.optionId === checkboxOption.optionIdName)[0];
      let option = {licenseId: activeLicenseId, optionId: applicationOption.id};
      const response = await createLicenseOption(option);
      if (response.error) {
        setErrorMessage(response.error);
        setLoadingOption(false);
        return;
      }
      let newLicenses = [...licenses];
      const licenseIndex = licenses.findIndex(license => license.id === response.licenseId); // was response.companyLicenseId
      const licenseOptions = newLicenses[licenseIndex].licenseOptions;
      newLicenses[licenseIndex].licenseOptions = [...licenseOptions, response];
      setLicenses(newLicenses);
      setActiveLicenseOptions([...activeLicenseOptions, {optionIdName: checkboxOption.optionIdName, id: response.id, optionName: checkboxOption.optionName}])
      let inactive = [...inactiveLicenseOptions];
      let idx = inactive.findIndex(ro => ro.optionIdName === checkboxOption.optionIdName);
      inactive.splice(idx, 1);
      setInactiveLicenseOptions(inactive);
    } else if (action === "delete") {
      const response = await deleteLicenseOption(checkboxOption.id);
      if (response.error) {
        setErrorMessage(response.error);
        setLoadingOption(false);
        return;
      }
      let newLicenses = [...licenses];
      const licenseIndex = licenses.findIndex(license => license.id === response.licenseId);
      const licenseOptions = newLicenses[licenseIndex].licenseOptions;
      const optionIndex = licenseOptions.findIndex(ro => ro.id === response.id);
      newLicenses[licenseIndex].licenseOptions.splice(optionIndex, 1);
      setLicenses(newLicenses);
      setInactiveLicenseOptions([...inactiveLicenseOptions, {optionIdName: checkboxOption.optionIdName, optionName: checkboxOption.optionName}])
      let active = [...activeLicenseOptions];
      let idx = active.findIndex(ro => ro.optionIdName === checkboxOption.optionIdName);
      active.splice(idx, 1);
      setActiveLicenseOptions(active);
    }
    setLoadingOption(false);
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const updateLicenseSubmit = async () => {
    const response = await updateLicense(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingLicense(false);
    setLicenses(licenses.map(license => license.id === response.id ? response : license));
    if (filtering) {
      setFilteredLicenses(filteredLicenses.map(registration => registration.id === response.id ? response : registration));
    }
  }

  const createLicenseSubmit = async () => {
    setErrorMessage('');
    if (!selectedData['companyId'] || !selectedData['applicationId']) {
      setErrorMessage("Please select a Company and Application");
      return;
    }
    if (selectedData['expirationDate'] == null || selectedData['expirationDate'] < Date.now()){
      setErrorMessage("Expiration Date must be later than today");
      return;
    }
    const response = await createLicense(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingLicense(false);
    const newLicenses = [...licenses, response];
    setLicenses(newLicenses);
    if (registrationColumns.length === 0) {
      setLicenseColumns(Object.keys(response));
    }
    setFiltering(false);
    setSearchText("");
    setFilteredLicenses([]);
  }

  const openEditLicenseModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Edit is clicked
    setEditingLicense(true);
    setSelectedData(data);
  }

  const openDetailLicenseModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Row is clicked
    setViewingDetailLicense(true);
    setSelectedData(data);
  }

  const cancelResetLicense = () => {
    setShowResetLicenseModal(false);
    setActiveLicenseId(-1)
  }

  const openResetLicenseModal = (e, registration) => {
    e.stopPropagation();
    setShowResetLicenseModal(true);
    setActiveLicenseId(registration.id);
  }

  const resetLicenseSubmit = async () => {
    const response = await resetLicense(activeLicenseId);
    if (response.error) {
      setErrorMessage(response.error);
      setShowResetLicenseModal(false);
      setActiveLicenseId(-1);
      return;
    }
    setLicenses(licenses.map(license => license.id === response.id ? response : license));
    if (filtering) {
      setFilteredLicenses(filteredLicenses.map(license => license.id === response.id ? response : license));
    }
    setShowResetLicenseModal(false);
    setActiveLicenseId(-1);
  }

  const activateDeactivateLicense = async (registration) => {
    setErrorMessage('');
    let newLicense = { ...registration };
    newLicense.isActive = !newLicense.isActive;
    const response = await updateLicense(newLicense);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setLicenses(licenses.map(license => license.id === response.id ? response : license));
    if (filtering) {
      setFilteredLicenses(filteredLicenses.map(license => license.id === response.id ? response : license));
    }
  }

  const activateDeactivateLicenseOption = async (registrationOption) => {
    setErrorMessage('');
    let newLicenseOption = { ...registrationOption };
    newLicenseOption.isActive = !newLicenseOption.isActive;
    const response = await updateLicenseOption(newLicenseOption);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    const licenseIndex = licenses.findIndex(license => license.id === response.licenseId);
    const license = licenses.filter(license => license.id === response.licenseId)[0];
    const optionIndex = license.licenseOptions.findIndex(option => option.id === response.id);
    let newLicenses = [...licenses];
    newLicenses[licenseIndex].licenseOptions[optionIndex] = response;
    setLicenses(newLicenses);
    if (filtering) {
      const licenseIndex = filteredLicenses.findIndex(license => license.id === response.licenseId);
      const license = filteredLicenses.filter(license => license.id === response.licenseId)[0];
      const optionIndex = license.licenseOptions.findIndex(option => option.id === response.id);
      let copyLicenses = [...filteredLicenses];
      copyLicenses[licenseIndex].licenseOptions[optionIndex] = response;
      setFilteredLicenses(copyLicenses);
    }
  }

  const addLicense = () => {
    if (!loading && !modifyError) {
      return (
        <div className="mt-4 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            onClick={openCreateLicenseModal}
          >
            Add License
          </button>
        </div>
      )
    }
  }

  const filterLicenses = (text) => {
    if (text === "") {
      setFilteredLicenses([]);
      setFiltering(false);
      setSearchText("");
      return;
    }
    let upperText = text.toUpperCase();
    const filteredLicenses = licenses.filter(function(r) {
      return (
        r.serialNumber.toUpperCase().includes(upperText) ||
        r.companyIdName.toUpperCase().includes(upperText) ||
        r.applicationIdName.toUpperCase().includes(upperText) ||
        r.notes.toUpperCase().includes(upperText)
      )
    });
    setSearchText(text);
    setFiltering(true);
    setFilteredLicenses(filteredLicenses);
  }

  const applicationHasOptions = (license) => {
    const applicationId = license.applicationId;
    const application = applications.filter(a => a.id === applicationId)[0];
    if (application) {
      return application.applicationOptions.length > 0;
    }
    return false;
  }

  const getApplicationOptionIdName = (license) => {
    let applicationOptions = [];
    if (applications.length > 0) {
      applications.forEach(p => {
        if (p.applicationOptions.length > 0) {
          p.applicationOptions.forEach(po => applicationOptions.push(po))
        }
      });
      let applicationOption = applicationOptions.filter(po => po.id === license.optionId)[0]
      return applicationOption.optionName;
    }
    return "";
  }

  const modalStyles = {
    content: {
      top: '25%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      <Modal
        isOpen={showResetLicenseModal}
        style={modalStyles}
      >
        <h2 className="text-center text-emerald-600 font-bold text-2xl mb-2 p-3">Reset License</h2>
        <p className="text-center text-gray-500 font-bold mb-5">Would you like to reset the company registration?</p>
        <div className="flex flex-col justify-center">
          <button
            onClick={() => cancelResetLicense()}
            className="flex justify-center py-1 px-1 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            onClick={() => resetLicenseSubmit()}
            className="flex justify-center py-1 px-1 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset
          </button>
        </div>
      </Modal>
      <div className="sm:flex sm:items-center items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Licenses </h1>
          <p className="mt-2 text-sm text-gray-700">A list of all company licenses.</p>
        </div>
        {loading && (
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Loader height={"h-6"} width={"w-6"} />
          </div>
        )}
        {addLicense()}
        {modifyError && (
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center opacity-50 rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            >
              Error
            </button>
          </div>
        )}
      </div>
      {licenses.length !== 0 && (
        <div className="flex justify-center">
          <div className="mt-5 mb-3 xl:w-96">
            <input
              type="search"
              className="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="filterLicenses"
              onChange={(e) => filterLicenses(e.target.value)}
              placeholder="Search serial numbers"
              value={searchText}
            />
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <DynamicTable
        rowData={licenses}
        legend={licenseKey}
        loading={loading}
        hasChildData={true}
        openCreateChildModal={openCreateLicenseOptionModal}
        activateDeactivateChild={activateDeactivateLicenseOption}
        activateDeactivateRow={activateDeactivateLicense}
        openEditRowModal={openEditLicenseModal}
        openDetailRowModal={openDetailLicenseModal}
        openResetModal={openResetLicenseModal}
        getParentValue={getApplicationOptionIdName}
        parentHasOptions={applicationHasOptions}
        removeChildEdit={true}
        filteredData={filteredLicenses}
        filtering={filtering}
        mobileLegend={mobileLicenseKey}
        noDataMessage={"No Licenses have been added"}
        childTitle={'Modify'}
        allowExpandOptions={false}
      />
      {editingLicense && (
        <CreateEditModal 
          form={editLicensesForm}
          setData={handleChange}
          setBool={handleBool}
          setDate={setDate}
          setSelect={handleSelect}
          canShowField={canViewUserCount}
          close={closeModal}
          data={selectedData}
          labels={licenseKey.dataMap}
          submit={updateLicenseSubmit}
          errorMessage={errorMessage}
          title={"Edit License"}
        />
      )}
      {viewingLicenseDetail && (
        <CreateEditModal 
          form={detailLicensesForm}
          close={closeModal}
          data={selectedData}
          labels={licenseKey.dataMap}
          errorMessage={errorMessage}
          hideSubmit={true}
          title={"License Detail"}
        />
      )}
      {creatingLicense && (
        <CreateEditModal 
          form={createFormWithSelectData}
          setData={handleChange}
          setBool={handleBool}
          setSelect={handleSelect}
          canShowField={canViewUserCountApplications}
          setDate={setDate}
          close={closeModal}
          data={selectedData}
          labels={licenseKey.dataMap}
          submit={createLicenseSubmit}
          errorMessage={errorMessage}
          title={"Create License"}
        />
      )}
      {creatingLicenseOption && (
        <CheckboxModal
          createFields={inactiveLicenseOptions}
          deleteFields={activeLicenseOptions}
          setCheckbox={createRemoveLicenseOption}
          close={closeModal}
          keys={{name: 'optionName'}}
          errorMessage={errorMessage}
          title={"License Options"}
          loading={loadingOption}
        />
      )}
    </div>
  )
}