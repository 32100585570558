import React, { useState, useEffect } from "react";
import Modal from 'react-modal';

import { getPhysicalCounts, updatePhysicalCount, createPhysicalCount, deletePhysicalCount, getProductsBySearch, updateProductParent } from '../services/PhysicalCounts';
import { getLocations } from "../services/Locations";

import CreateEditModal from "../components/CreateEditModal";
import { physicalCountKey } from "../utils/labelMap";
import { detailPhysicalCountForm, createPhysicalCountForm, editChildPhysicalCountForm, editParentPhysicalCountForm } from "../utils/forms";
import DynamicTable from "../components/DynamicTable";

export default function PhysicalCounts() {
  const [physicalCounts, setPhysicalCounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewingPhysicalCountDetail, setViewingPhysicalCountDetail] = useState(false);
  const [creatingPhysicalCount, setCreatingPhysicalCount] = useState(false);
  const [editingPhysicalCountChild, setEditingPhysicalCountChild] = useState(false);
  const [editingPhysicalCountParent, setEditingPhysicalCountParent] = useState(false);
  const [createFormWithSelectData, setCreateFormWithSelectData] = useState([]);
  const [childEditFormWithSelectData, setChildEditFromWithSelectData] = useState([]);
  const [parentEditFormWithSelectData, setParentEditFromWithSelectData] = useState([]);
  const [showDeleteCountModal, setShowDeleteCountModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [usernameSortAsc, setUsernameSortAsc] = useState(true);
  const [activePhysicalCountId, setActivePhysicalCountId] = useState({});

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const data = await getPhysicalCounts();
        const locations = await getLocations();
        addDataToSelectOptions(locations, "locationId", "locationId")

        setLocations(locations);
        if (data.length > 0) {
          setPhysicalCounts(data);
        }
        setLoading(false);
    }
    fetchData();
  }, []);

  const filterSkus = async (inputValue) => {
    let products = await getProductsBySearch(inputValue);
    return products.map(p => ({ value: p.productId, label: p.identifier }));
    
  };

  const sortByUsername = (parentIndex) => {
    let physicalCountCopy = [...physicalCounts];
    let childrenCopy = [...physicalCountCopy[parentIndex].physicalCountChildren]
    if (usernameSortAsc) {
      childrenCopy.sort(compare);
    }
    else {
      childrenCopy.sort(compare)
    }
    setUsernameSortAsc(!usernameSortAsc);
    physicalCountCopy[parentIndex].physicalCountChildren = childrenCopy;
    setPhysicalCounts(physicalCounts)
  }

  const compare = (a, b) => {
    if (a.createdByUsername < b.createdByUsername) {
      if (usernameSortAsc) {
        return -1;
      }
      return 1;
    }
    if (a.createdByUsername > b.createdByUsername) {
      if (usernameSortAsc) {
        return 1;
      }
      return -1;
    }
    return 0;
  }

  const promiseOptions = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterSkus(inputValue));
    }, 1000);
  });

  const openCreatePhysicalCountModal = () => {
    setSelectedData({});
    setCreatingPhysicalCount(true);
  }

  const closeModal = () => {
    setCreatingPhysicalCount(false);
    setEditingPhysicalCountParent(false);
    setEditingPhysicalCountChild(false);
    setViewingPhysicalCountDetail(false);
    setActivePhysicalCountId({});
    setErrorMessage('');
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const handleAutocomplete = (e) => {
    
    setSelectedData(selectedData => ({
      ...selectedData,
      "productId": e.value,
    }));
  }

  const handleSelect = e => {
    let { name, value } = e.target;
    if (value === 'default') {
      let data = {...selectedData};
      let key;
      let field = e.target.field;
      if (name === 'locationId') {
        key = 'locationId';
        data['locationId'] = field;
      }
      delete data[key];
      setSelectedData(data);
      return;
    }
    if (name === "locationId") {
      const location = locations.filter(c => c[name] === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        locationId: location[0].locationId
      }));
    }
    if (name === "location"){
      const location = locations.filter(c => c.locationId === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        locationId: location[0].locationId
      }));
    }
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const addDataToSelectOptions = (data, key, value) => {
    let createFormCopy = [...createPhysicalCountForm];
    let keyIndex = createFormCopy.findIndex(f => f.name === key);
    let keyOptions = data.map(c => c[value]);
    createFormCopy[keyIndex].options = keyOptions;
    keyIndex = createFormCopy.findIndex(f => f.name === "sku");
    createFormCopy[keyIndex].options = promiseOptions;
    setCreateFormWithSelectData(createFormCopy);

    let editFormCopy = [...editChildPhysicalCountForm];
    keyIndex = editFormCopy.findIndex(f => f.name === key);
    keyOptions = data.map(c => c[value]);
    keyIndex = editFormCopy.findIndex(f => f.name === "sku");
    setChildEditFromWithSelectData(editFormCopy);

    let editParentFormCopy = [...editParentPhysicalCountForm];
    keyIndex = createFormCopy.findIndex(f => f.name === key);
    keyOptions = data.map(c => c[value]);
    editParentFormCopy[keyIndex].options = keyOptions;
    keyIndex = createFormCopy.findIndex(f => f.name === "sku");
    editParentFormCopy[keyIndex].options = promiseOptions;
    setParentEditFromWithSelectData(editParentFormCopy);
  }

  const openEditParentModal = (e, data) => {
    e.stopPropagation();
    data.identifier = data.sku;
    setEditingPhysicalCountParent(true);
    setSelectedData(data);
  }

  const updatePhysicalCountParentSubmit = async () => {
    setErrorMessage('');
    try{
      console.log(selectedData)
      selectedData.locationId = locations.find(x => x.locationId == selectedData.location).id;
      selectedData.inventoryId = selectedData.physicalCountChildren[0].inventoryId;
    } catch (e) {
      console.log(e)
      setErrorMessage("All fields are required");
      return;
    }
    const response = await updateProductParent(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingPhysicalCountParent(false);
    setPhysicalCounts(response);
  }
 
  const updatePhysicalCountChildSubmit = async () => {
    setErrorMessage('');
    try{
      selectedData.locationId = locations.find(x => x.locationId == selectedData.location).id;

    } catch (e) {
      setErrorMessage("All fields are required");
      return;
    }
    const response = await updatePhysicalCount(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingPhysicalCountChild(false);
    const newPhysicalCounts = await getPhysicalCounts();
    setPhysicalCounts(newPhysicalCounts);
  }

  const createPhysicalCountSubmit = async () => {
    setErrorMessage('');
    try{
      selectedData.locationId = locations.find(x => x.locationId == selectedData.locationId).id;

    } catch (e) {
      setErrorMessage("All fields are required");
      return;
    }
    const response = await createPhysicalCount(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingPhysicalCount(false);
    const newPhysicalCounts = await getPhysicalCounts();
    setPhysicalCounts(newPhysicalCounts);
  }

  const openEditPhysicalCountChildModal = (data) => {
    setEditingPhysicalCountChild(true);
    setSelectedData(data);
  }

  const cancelDeleteCount = () => {
    setShowDeleteCountModal(false);
    setActivePhysicalCountId(-1)
  }

  const openDeleteCountModal = (e, count) => {
    e.stopPropagation();
    setShowDeleteCountModal(true);
    setActivePhysicalCountId(count.id);
  }

  const deleteCountSubmit = async () => {
    const response = await deletePhysicalCount(activePhysicalCountId);
    if (response.error) {
      setErrorMessage(response.error);
      setShowDeleteCountModal(false);
      setActivePhysicalCountId(-1);
      return;
    }
    const newPhysicalCounts = await getPhysicalCounts();
    setPhysicalCounts(newPhysicalCounts);
    setShowDeleteCountModal(false);
    setActivePhysicalCountId(-1);
  }

const modalStyles = {
  content: {
    top: '25%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      <Modal
        isOpen={showDeleteCountModal}
        style={modalStyles}
      >
        <h2 className="text-center text-emerald-600 font-bold text-2xl mb-2 p-3">Delete Count</h2>
        <p className="text-center text-gray-500 font-bold mb-5">Would you like to delete the count?</p>
        <div className="flex flex-col justify-center">
          <button
            onClick={() => cancelDeleteCount()}
            className="flex justify-center py-1 px-1 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            onClick={() => deleteCountSubmit()}
            className="flex justify-center py-1 px-1 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Delete
          </button>
        </div>
      </Modal>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Counts </h1>
          <p className="mt-2 text-sm text-gray-700">A list of all inventory counts grouped by parent SKU.</p>
        </div>
        <div className="sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            onClick={openCreatePhysicalCountModal}
          >
            Add Count
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 mt-2 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <DynamicTable
        rowData={physicalCounts}
        loading={loading}
        legend={physicalCountKey}
        hasChildData={true}
        openEditChildModal={openEditPhysicalCountChildModal}
        openEditRowModal={openEditParentModal}
        openDetailRowModal={() => console.log("no detail for parent")}
        openDeleteModal={openDeleteCountModal}
        allowDeleteChild={true}
        mobileLegend={physicalCountKey}
        childTitle={"Counts"}
        sortByField={sortByUsername}
        allowExpandOptions={true}
        noDataMessage={"No Counts have been added"}
      />
      {creatingPhysicalCount && (
        <CreateEditModal 
          form={createFormWithSelectData}
          setData={handleChange}
          setSelect={handleSelect}
          setBool={handleBool}
          setAutocomplete={handleAutocomplete}
          close={closeModal}
          data={selectedData}
          labels={physicalCountKey.dataMap}
          submit={createPhysicalCountSubmit}
          errorMessage={errorMessage}
          title={"Create Count"}
        />
      )}
      {editingPhysicalCountParent && (
        <CreateEditModal 
          form={childEditFormWithSelectData}
          setData={handleChange}
          setSelect={handleSelect}
          setBool={handleBool}
          setAutocomplete={handleAutocomplete}
          close={closeModal}
          data={selectedData}
          labels={physicalCountKey.dataMap}
          submit={updatePhysicalCountParentSubmit}
          errorMessage={errorMessage}
          title={"Edit Counts"}
        />
      )}
      {editingPhysicalCountChild && (
        <CreateEditModal 
          form={parentEditFormWithSelectData}
          setData={handleChange}
          setSelect={handleSelect}
          setBool={handleBool}
          setAutocomplete={handleAutocomplete}
          close={closeModal}
          data={selectedData}
          labels={physicalCountKey.childMap}
          submit={updatePhysicalCountChildSubmit}
          errorMessage={errorMessage}
          title={"Edit Count"}
        />
      )}
      {viewingPhysicalCountDetail && (
        <CreateEditModal 
          form={detailPhysicalCountForm}
          close={closeModal}
          data={selectedData}
          labels={physicalCountKey.dataMap}
          errorMessage={errorMessage}
          hideSubmit={true}
          title={"Count Detail"}
        />
      )}
    </div>
  )
}