import React,  { useState }  from 'react';
import cloud9Logo from '../images/cloud9fx_icon.webp';
import Footer from '../components/Footer';
import PropTypes from 'prop-types';
import Loader from "../components/Loader";


export const CompanySelect = (props) => {
    const [selectedCompany, setSelectedCompany] = useState();

    return (
        <>
            <div className="min-h-full flex items-center flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img
                            className="mx-auto text-center"
                            src={cloud9Logo}
                            height={100}
                            width={300}
                            alt="Logo"
                        />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-500">
                            Select your company
                        </h2>
                    </div>
                    {props.user ? 
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                            name="company-select"
                            onChange={(e) => setSelectedCompany(e.target.value)}
                            value={selectedCompany}
                            className="block w-full pr-10 pl-2.5 py-2 text-base font-normal text-gray-700 bg-gray-50 border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-emerald-600 focus:outline-none"
                        >
                            <option value={'default'}>Select...</option>
                            {props.user.companies.map((item, index) => {
                                return <option key={index} value={item.id} className="border border-solid border-gray-300 rounded-lg block w-full">{item.name}</option>
                            })}
                        </select>
                        <button
                            type="button"
                            className="my-10 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                            onClick={() => props.setUserWithCompany(selectedCompany)}
                        >
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <svg className="h-5 w-5 text-gray-200 group-hover:text-gray-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                </svg>
                            </span>
                            Submit
                        </button>
                    </div>
                    :
                    <Loader/>}
                    
                </div>
                <Footer />
            </div>
        </>
    );
};


CompanySelect.propTypes = {
    selectedCompany: PropTypes.object,
    setCompany: PropTypes.func,
    user: PropTypes.object,
    setUserWithCompany: PropTypes.func
  }