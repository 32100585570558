import React from "react";

import Footer from '../components/Footer';


export default function Dashboard() {

  let user = JSON.parse(sessionStorage.getItem("user"));

  return (

      <div className="px-4">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="border-4 border-dashed border-gray-200 rounded-lg h-96">
              <h3 className="m-5">Welcome! {user?.email}</h3>
            </div>
          </div>
        </div>
        <Footer />
      </div>

  )
}