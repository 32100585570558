import React from 'react'
import Modal from 'react-modal';
import PropTypes from 'prop-types';

Modal.setAppElement('#root');

export default function AreYouSureModal(props) {

  const customStyles = {
    content: {
      top: '25%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        style={customStyles}
      >
        <h2 className="text-center text-emerald-600 font-bold text-2xl mb-2 p-3">Are you sure you want to close?</h2>
        <p className="text-center text-gray-500 font-bold mb-5">All data will be lost</p>
        <div className="flex flex-row">
          <button
            onClick={() => props.yes()}
            className="mr-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Exit
          </button>
          <button
            onClick={() => props.no()}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Go Back
          </button>
        </div>
      </Modal>
    </div>
  )
}

AreYouSureModal.propTypes = {
  isOpen: PropTypes.bool,
  yes: PropTypes.func,
  no: PropTypes.func
}