import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getPhysicalCounts = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/InventoryCount/Aggregate', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updatePhysicalCount = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/InventoryCount', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createPhysicalCount = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/InventoryCount/ByLocation', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const deletePhysicalCount = async (data) => {
    const config = {
      withCredentials: true
    };
  
    try {
      const response = await axios.delete(`/InventoryCount?id=${data}`, config);
      return response.data;
    } catch (error) {
      return formatErrorResponse(error.response);
    }
}
  
export const getAllProducts = async () => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/FrontierProduct/All', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const getProductsBySearch = async (searchTerm) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get(`/FrontierProduct?searchTerm=${searchTerm}`, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateProductParent = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/InventoryCount/ByParent', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}