import React from "react";
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function IsActiveToggle(props) {
  return (
      <Switch.Group as="div" className="">
        <Switch
            checked={props.active}
            onChange={() => props.update(props.data)}
            className={classNames(
                props.active ? 'focus:ring-emerald-600 bg-emerald-400' : 'focus:ring-red-400 bg-red-200',
                'relative inline-flex align-middle flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
            )}
        >
            <span
                aria-hidden="true"
                className={classNames(
                    props.active ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
      </Switch.Group>
    )
}

IsActiveToggle.propTypes = {
    update: PropTypes.func,
    active: PropTypes.bool,
    data: PropTypes.object,
}