import React from "react";
import Loader from "../components/Loader";
import PropTypes from 'prop-types';

export default function CheckboxModal(props) {

    const addFields = () => {
        return props.createFields.map((field) => {
            return (
                <div className="mb-2" key={field}>
                    <button
                        type="button"
                        onClick={() => props.setCheckbox(field, 'create')}
                        className="w-full text-white bg-gray-600 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-1 text-center"
                        disabled={props.loading ? true : false}
                    >
                    {field[props.keys.name]}
                    </button>
                </div>
                )
            }
        )
    }

    const deleteFields = () => {
        return props.deleteFields.map((field) => {
            return (
                <div className="mb-2" key={field}>
                    <button
                        type="button"
                        onClick={() => props.setCheckbox(field, 'delete')}
                        className="w-full text-white bg-emerald-500 hover:bg-emerald-400 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-2 py-1 text-center"
                        disabled={props.loading ? true : false}
                    >
                     {field[props.keys.name]}
                    </button>
                </div>
                )
            }
        )
    }

    return (
        <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full mt-20 md:inset-0 h-modal md:h-full">
            <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <div className="relative p-4 w-full max-w-4xl h-full md:h-auto mx-auto">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="flex justify-end p-2">
                        <button onClick={props.close} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="authentication-modal">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <h3 className="text-xl flex item-center justify-center font-medium text-gray-900 mb-6 ml-6">{props.title}</h3>
                    {props.errorMessage && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative ml-6 mr-6 mb-6" role="alert">
                            <strong className="font-bold">Error: </strong>
                            <span className="block sm:inline">{props.errorMessage}</span>
                        </div>
                    )}
                    <div className={props.loading ? "flex justify-between opacity-70" : "flex justify-between mb-8"}>
                        <div className="grid grid-cols-1 gap-4 mr-12 ml-12">
                            <p className="text-lg font-semibold">{props.createFields.length > 0 ? "Available Options" : "All Options are active"}</p>
                            {props.createFields.length > 0 && (
                                <div className="mb-8">
                                    {addFields()}
                                </div>
                            )}
                        </div>
                        {props.loading && (
                        <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                            <Loader height={"h-6"} width={"w-6"} />
                        </div>
                        )}
                        <div className="grid grid-cols-1 gap-4 ml-12 mr-12">
                            <p className="text-lg font-semibold text-emerald-700">{props.deleteFields.length > 0 ? "Active Options" : "No active options"}</p>
                            {props.deleteFields.length > 0 && (
                                <div className="mb-8">
                                    {deleteFields()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CheckboxModal.propTypes = {
    createFields: PropTypes.func,
    deleteFields: PropTypes.func,
    setCheckbox: PropTypes.func,
    close: PropTypes.func,
    keys: PropTypes.object,
    errorMessage: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool
}