import React from "react";


export default function Footer() {
  return (
    <footer className="bg-white fixed bottom-0 flex items-center justify-center mx-auto">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-center lg:px-8">
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-400">
            &copy; 2023 Cloud9 Retail. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
