import React, { useState, useEffect } from "react";
import { getApplicationList, updateApplication, createApplication, updateApplicationOption, createApplicationOption } from '../services/Applications';

import CreateEditModal from "../components/CreateEditModal";
import { applicationKey, mobileApplicationKey } from "../utils/labelMap";
import { applicationForm, applicationOptionForm, detailApplicationForm } from "../utils/forms";
import DynamicTable from "../components/DynamicTable";

export default function Applications() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingApplication, setEditingApplication] = useState(false);
  const [viewingApplicationDetail, setViewingApplicationDetail] = useState(false);
  const [creatingApplication, setCreatingApplication] = useState(false);
  const [editingApplicationOption, setEditingApplicationOption] = useState(false);
  const [creatingApplicationOption, setCreatingApplicationOption] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [activeApplicationId, setActiveApplicationId] = useState({});

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const data = await getApplicationList();
        if (data.length > 0) {
          setApplications(data);
        }
        setLoading(false);
    }
    fetchData();
  }, []);

  const openCreateApplicationModal = () => {
    setSelectedData({});
    setCreatingApplication(true);
  }

  const openCreateApplicationOptionModal = (applicationId) => {
    setSelectedData({});
    setCreatingApplicationOption(true);
    setActiveApplicationId(applicationId);
  }

  const openApplicationDetailModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Row is clicked
    setViewingApplicationDetail(true);
    setSelectedData(data);
  }

  const closeModal = () => {
    setEditingApplication(false);
    setCreatingApplication(false);
    setEditingApplicationOption(false);
    setCreatingApplicationOption(false);
    setViewingApplicationDetail(false);
    setActiveApplicationId({});
    setErrorMessage('');
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const updateApplicationSubmit = async () => {
    setErrorMessage('');
    const response = await updateApplication(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingApplication(false);
    setApplications(applications.map(application => application.id === response.id ? response : application));
  }

  const createApplicationOptionSubmit = async () => {
    setErrorMessage('');
    let applicationOptionData = { ...selectedData };
    applicationOptionData.applicationId = activeApplicationId;
    const response = await createApplicationOption(applicationOptionData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingApplicationOption(false);
    let newApplications = [...applications];
    const applicationIndex = applications.findIndex(application => application.id === response.applicationId);
    const applicationOptions = newApplications[applicationIndex].applicationOptions;
    newApplications[applicationIndex].applicationOptions = [...applicationOptions, response];
    setApplications(newApplications);
  }

  const updateApplicationOptionSubmit = async () => {
    setErrorMessage('');
    const response = await updateApplicationOption(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingApplicationOption(false);
    const applicationIndex = applications.findIndex(application => application.id === response.applicationId);
    const application = applications.filter(application => application.id === response.applicationId)[0];
    const optionIndex = application.applicationOptions.findIndex(option => option.id === response.id);
    let newApplications = [...applications];
    newApplications[applicationIndex].applicationOptions[optionIndex] = response;
    setApplications(newApplications);
  }

  const createApplicationSubmit = async () => {
    setErrorMessage('');
    const response = await createApplication(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreatingApplication(false);
    const newApplications = [...applications, response];
    setApplications(newApplications);
  }

  const openEditApplicationModal = (e, data) => {
    e.stopPropagation(); // stops application options from rendering when Edit is clicked
    setEditingApplication(true);
    setSelectedData(data);
  }

  const openEditApplicationOptionModal = (data) => {
    setEditingApplicationOption(true);
    setSelectedData(data);
  }

  const activateDeactivateApplication = async (application) => {
    setErrorMessage('');
    let newApplication = { ...application };
    newApplication.isActive = !newApplication.isActive;
    const response = await updateApplication(newApplication);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setApplications(applications.map(application => application.id === response.id ? response : application));
  }

  const activateDeactivateApplicationOption = async (applicationOption) => {
    setErrorMessage('');
    let newApplicationOption = { ...applicationOption };
    newApplicationOption.isActive = !newApplicationOption.isActive;
    const response = await updateApplicationOption(newApplicationOption);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    const applicationIndex = applications.findIndex(application => application.id === response.applicationId);
    const application = applications.filter(application => application.id === response.applicationId)[0];
    const optionIndex = application.applicationOptions.findIndex(option => option.id === response.id);
    let newApplications = [...applications];
    newApplications[applicationIndex].applicationOptions[optionIndex] = response;
    setApplications(newApplications);
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Applications </h1>
          <p className="mt-2 text-sm text-gray-700">A list of all Cloud9 Retail applications.</p>
        </div>
        <div className="sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            onClick={openCreateApplicationModal}
          >
            Add Application
          </button>
        </div>
      </div>
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 mt-2 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <DynamicTable
        rowData={applications}
        loading={loading}
        legend={applicationKey}
        hasChildData={true}
        openCreateChildModal={openCreateApplicationOptionModal}
        activateDeactivateChild={activateDeactivateApplicationOption}
        openEditChildModal={openEditApplicationOptionModal}
        activateDeactivateRow={activateDeactivateApplication}
        openEditRowModal={openEditApplicationModal}
        openDetailRowModal={openApplicationDetailModal}
        mobileLegend={mobileApplicationKey}
        noDataMessage={"No Applications have been added"}
        allowExpandOptions={true}
      />
      {editingApplication && (
        <CreateEditModal 
          form={applicationForm}
          setData={handleChange}
          setBool={handleBool}
          close={closeModal}
          data={selectedData}
          labels={applicationKey.dataMap}
          submit={updateApplicationSubmit}
          errorMessage={errorMessage}
          title={"Edit Application"}
        />
      )}
      {creatingApplication && (
        <CreateEditModal 
          form={applicationForm}
          setData={handleChange}
          setBool={handleBool}
          close={closeModal}
          data={selectedData}
          labels={applicationKey.dataMap}
          submit={createApplicationSubmit}
          errorMessage={errorMessage}
          title={"Create Application"}
        />
      )}
      {editingApplicationOption && (
        <CreateEditModal 
          form={applicationOptionForm}
          setData={handleChange}
          setBool={handleBool}
          close={closeModal}
          data={selectedData}
          labels={applicationKey.childMap}
          submit={updateApplicationOptionSubmit}
          errorMessage={errorMessage}
          title={"Edit Application Option"}
        />
      )}
      {creatingApplicationOption && (
        <CreateEditModal 
          form={applicationOptionForm}
          setData={handleChange}
          setBool={handleBool}
          close={closeModal}
          data={selectedData}
          labels={applicationKey.childMap}
          submit={createApplicationOptionSubmit}
          errorMessage={errorMessage}
          title={"Create Application Option"}
        />
      )}
      {viewingApplicationDetail && (
        <CreateEditModal 
          form={detailApplicationForm}
          close={closeModal}
          data={selectedData}
          labels={applicationKey.dataMap}
          errorMessage={errorMessage}
          hideSubmit={true}
          title={"Application Detail"}
        />
      )}
    </div>
  )
}