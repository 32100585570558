import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import cloud9Logo from '../images/cloud9fx_icon.webp';
// import { msalConfig } from "../authConfig.js";

export default function TopNavigation() {
  const [isClosed, setClosed] = useState(true);
  const ref = useRef(null);
  const { instance } = useMsal();

  const handleLogoutRedirect = () => {
    const logoutRequest = {
      postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    };
    instance.logoutRedirect(logoutRequest);
    //instance.logoutRedirect();
};

let activeAccount;

if (instance) {
    activeAccount = instance.getActiveAccount();
}

  const logout = () => {
    // auth.signout(() => navigate("/"));
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("company");
    handleLogoutRedirect();
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        isClosed && setClosed(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);


  return (
    <nav ref={ref} className="bg-white shadow-sm px-2 sm:px-4 py-2.5 rounded">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <Link to="/" className="flex">
          <div>
            <img
              className="mx-auto text-center"
              src={cloud9Logo}
              height={50}
              width={150}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="flex items-center md:order-2">
          <button type="button" onClick={() => setClosed(!isClosed)} className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
            <span className="sr-only">Open user menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </button>
          {!isClosed && (
            <div className="z-50 top-12 absolute text-base list-none bg-white rounded divide-y divide-gray-100 shadow" id="dropdown">
              <div className="py-3 px-4">
                <span className="block text-sm text-gray-900">{activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.email ? activeAccount.idTokenClaims.email : 'Unknown'}</span>
                <span className="block text-sm font-medium text-gray-500 truncate">{activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.email ? activeAccount.idTokenClaims.email : 'Unknown'}</span>
              </div>
              <ul className="py-1" aria-labelledby="dropdown">
                <li>
                  <Link to="/user" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">My Profile</Link>
                </li>
                <li>
                  <a href="#" onClick={() => logout()} className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">Logout</a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}
