import React, { useState, useEffect } from "react";
import {
  getDeviceRegistrationList,
  updateDeviceRegistration,
  assignDeviceRegistration, 
  resetDeviceRegistration
} from '../services/DeviceRegistrations';
import { getCompanyList } from '../services/Companies';
import Loader from "../components/Loader";
import Modal from 'react-modal';

import CreateEditModal from "../components/CreateEditModal";
import { deviceRegistrationKey, endUserDeviceRegistrationKey, mobileDeviceRegistrationKey } from "../utils/labelMap";
import { assignDeviceRegistrationsForm,
  endUserAssignDeviceRegistrationsForm,
  editDeviceRegistrationsForm,
  detailDeviceRegistrationsForm,
  endUserDetailDeviceRegistrationsForm,
  endUserEditDeviceRegistrationsForm } from "../utils/forms";
import DynamicTable from "../components/DynamicTable";

export default function DeviceRegistrations() {
  const [deviceRegistrations, setDeviceRegistrations] = useState([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRegistration, setEditingRegistration] = useState(false);
  const [viewingRegistrationDetail, setViewingDetailRegistration] = useState(false);
  const [creatingRegistration, setCreatingRegistration] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [activeRegistrationId, setActiveRegistrationId] = useState(-1);
  const [createFormWithSelectData, setCreateFormWithSelectData] = useState([]);
  const [modifyError, setModifyError] = useState(false);
  const [showResetRegistrationModal, setShowResetRegistrationModal] = useState(false);
  const [showUserCountExceededModal, setShowUserCountExceededModal] = useState(false);

  let user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    async function fetchRegistrations() {
        setLoading(true);
        const data = await getDeviceRegistrationList();
        if (data.length > 0) {
          setDeviceRegistrations(data);
          //setDeviceRegistrationColumns(Object.keys(data[0]));
        }
        setLoading(false);
    }
    if (deviceRegistrations.length === 0){
      fetchRegistrations();
    }

    async function fetchCompanies() {
      setLoading(true);
        const companies = await getCompanyList();
        if (companies.error) {
          setModifyError(true);
        }
        if (companies.length > 0) {
          setCompanies(companies);
          addDataToSelectOptions(companies, 'companyIdName', 'companyId');
        }
        setLoading(false);
    }
    if (companies.length === 0) {
      fetchCompanies();
    }
  }, []);

  const addDataToSelectOptions = (data, key, value) => {
    let createFormCopy = [...assignDeviceRegistrationsForm];
    const keyIndex = createFormCopy.findIndex(f => f.name === key);
    const keyOptions = data.map(c => c[value]);
    createFormCopy[keyIndex].options = keyOptions;
    setCreateFormWithSelectData(createFormCopy);
  }

  const isPrivilegedUser = () => {
    return true;
  }

  const openCreateRegistrationModal = () => {
    setSelectedData({});
    setCreatingRegistration(true);
  }

  const closeModal = () => {
    setEditingRegistration(false);
    setViewingDetailRegistration(false);
    setCreatingRegistration(false);
    setActiveRegistrationId(-1)
    setErrorMessage('');
  }

  const setDate = (value, name) => {
    let utcDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0);
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: utcDate
    }))
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const handleSelect = e => {
    let { name, value } = e.target;
    if (value === 'default') {
      let data = {...selectedData};
      let key;
      let field = e.target.field;
      if (name === 'companyIdName') {
        key = 'companyId';
        data['companyIdName'] = field;
      }
      delete data[key];
      setSelectedData(data);
      return;
    }
    if (name === "companyIdName") {
      const company = companies.filter(c => c['companyId'] === value);
      setSelectedData(selectedData => ({
        ...selectedData,
        [name]: value,
        companyId: company[0].id
      }));
    }
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const updateDeviceRegistrationSubmit = async () => {
    const response = await updateDeviceRegistration(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditingRegistration(false);
    setDeviceRegistrations(deviceRegistrations.map(registration => registration.id === response.id ? response : registration));
    if (filtering) {
      setFilteredRegistrations(filteredRegistrations.map(registration => registration.id === response.id ? response : registration));
    }
    setErrorMessage('');
  }

  const createDeviceRegistrationSubmit = async () => {
    setErrorMessage('');
    const privilegedUser = isPrivilegedUser(); 
    if (privilegedUser) {
      if (!selectedData['companyId']) {
        setErrorMessage("Please select a Company");
        return;
      }
    } else {
      selectedData['companyIdName'] = user.company;
    }
    const response = await assignDeviceRegistration(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    if (!privilegedUser && response.errorText) {
      setErrorMessage(response.errorText);
      return;
    }
    if (response.userCountExceeded && privilegedUser) {
      setErrorMessage(response.errorText);
      setShowUserCountExceededModal(true);
      setCreatingRegistration(false);
      return;
    }
    setCreatingRegistration(false);

    if (privilegedUser) {
      setDeviceRegistrations(deviceRegistrations.map(registration => registration.id === response.id ? response : registration));
      if (filtering) {
        setFilteredRegistrations(filteredRegistrations.map(registration => registration.id === response.id ? response : registration));
      }
    } else {
      const newDeviceRegistrations = [...deviceRegistrations, response];
      setDeviceRegistrations(newDeviceRegistrations);
    }

    setErrorMessage('');
    setFiltering(false);
    setSearchText("");
    setFilteredRegistrations([]);
    setShowUserCountExceededModal(false);
  }

  const openEditRegistrationModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Edit is clicked
    setEditingRegistration(true);
    setSelectedData(data);
  }

  const openDetailRegistrationModal = (e, data) => {
    e.stopPropagation(); // stops registration options from rendering when Row is clicked
    setViewingDetailRegistration(true);
    setSelectedData(data);
  }

  const cancelResetRegistration = () => {
    setShowResetRegistrationModal(false);
    setActiveRegistrationId(-1)
  }

  const openResetRegistrationModal = (e, registration) => {
    e.stopPropagation();
    setShowResetRegistrationModal(true);
    setActiveRegistrationId(registration.id);
  }

  const removeUserCountModal = () => {
    setErrorMessage("");
    setShowUserCountExceededModal(false);
  }

  const forceRegistrationSubmit = () => {
    selectedData['forceRegistration'] = true;
    createDeviceRegistrationSubmit();
    setShowUserCountExceededModal(false);
  }

  const resetRegistrationSubmit = async () => {
    const deviceRegistration = deviceRegistrations.filter(dr => dr.id === activeRegistrationId)[0];
    const response = await resetDeviceRegistration(deviceRegistration.deviceId);
    if (response.error) {
      setErrorMessage(response.error);
      setShowResetRegistrationModal(false);
      setActiveRegistrationId(-1);
      return;
    }
    if (isPrivilegedUser()) {
      setDeviceRegistrations(deviceRegistrations.map(registration => registration.id === response.id ? response : registration));
      if (filtering) {
        setFilteredRegistrations(filteredRegistrations.map(registration => registration.id === response.id ? response : registration));
      }
    } else {
      const newDeviceRegistrations = deviceRegistrations.filter(dr => dr.id !== response.id);
      setDeviceRegistrations(newDeviceRegistrations);
    }
    setShowResetRegistrationModal(false);
    setActiveRegistrationId(-1);
  }

  const activateDeactivateDeviceRegistration = async (registration) => {
    setErrorMessage('');
    let newRegistration = { ...registration };
    newRegistration.isActive = !newRegistration.isActive;
    newRegistration.isActiveRequest = true;
    const response = await updateDeviceRegistration(newRegistration);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setDeviceRegistrations(deviceRegistrations.map(registration => registration.id === response.id ? response : registration));
    if (filtering) {
      setFilteredRegistrations(filteredRegistrations.map(registration => registration.id === response.id ? response : registration));
    }
  }


  const addRegistration = () => {
    if (!loading && !modifyError) {
      return (
        <div className="mt-4 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            onClick={openCreateRegistrationModal}
          >
            Register New Device
          </button>
        </div>
      )
    }
  }

  const filterRegistrations = (text) => {
    if (text === "") {
      setFilteredRegistrations([]);
      setFiltering(false);
      setSearchText("");
      return;
    }
    let upperText = text.toUpperCase();
    const filteredRegistrations = deviceRegistrations.filter(function(r) {
      return (
        r.deviceName?.toUpperCase().includes(upperText) ||
        r.deviceToken?.toUpperCase().includes(upperText) ||
        r.productIdName?.toUpperCase().includes(upperText) ||
        r.companyIdName?.toUpperCase().includes(upperText)
      )
    });
    setSearchText(text);
    setFiltering(true);
    setFilteredRegistrations(filteredRegistrations);
  }

  const modalStyles = {
    content: {
      top: '25%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      <Modal
        isOpen={showResetRegistrationModal}
        style={modalStyles}
      >
        <h2 className="text-center text-emerald-600 font-bold text-2xl mb-2 p-3">Reset Device Registration</h2>
        <p className="text-center text-gray-500 font-bold mb-5">Would you like to reset the device registration?</p>
        <div className="flex flex-col justify-center">
          <button
            onClick={() => cancelResetRegistration()}
            className="flex justify-center py-1 px-1 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            onClick={() => resetRegistrationSubmit()}
            className="flex justify-center py-1 px-1 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showUserCountExceededModal}
        style={modalStyles}
      >
        <h2 className="text-center text-emerald-600 font-bold text-2xl mb-2 p-3">Would you like to force the device to register?</h2>
        <p className="text-center text-gray-500 font-bold mb-5">{errorMessage}</p>
        <div className="flex flex-col justify-center">
          <button
            onClick={() => removeUserCountModal()}
            className="flex justify-center py-1 px-1 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            onClick={() => forceRegistrationSubmit()}
            className="flex justify-center py-1 px-1 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Force
          </button>
        </div>
      </Modal>
      <div className="sm:flex sm:items-center items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Device Registrations </h1>
          <p className="mt-2 text-sm text-gray-700">A list of all device registrations.</p>
        </div>
        {loading && (
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Loader height={"h-6"} width={"w-6"} />
          </div>
        )}
        {addRegistration()}
        {modifyError && (
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center opacity-50 rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            >
              Error
            </button>
          </div>
        )}
      </div>
      {deviceRegistrations.length !== 0 && (
        <div className="flex justify-center">
          <div className="mt-5 mb-3 xl:w-96">
            <input
              type="search"
              className="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="filterRegistrations"
              onChange={(e) => filterRegistrations(e.target.value)}
              placeholder="Search devices"
              value={searchText}
            />
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <DynamicTable
        rowData={deviceRegistrations}
        legend={isPrivilegedUser() ? deviceRegistrationKey : endUserDeviceRegistrationKey}
        loading={loading}
        activateDeactivateRow={activateDeactivateDeviceRegistration}
        openEditRowModal={openEditRegistrationModal}
        openDetailRowModal={openDetailRegistrationModal}
        openResetModal={openResetRegistrationModal}
        filteredData={filteredRegistrations}
        filtering={filtering}
        mobileLegend={mobileDeviceRegistrationKey}
        allowExpandOptions={true}
        noDataMessage={"No Device Registrations have been added"}
      />
      {creatingRegistration && (
        <CreateEditModal 
          form={isPrivilegedUser() ? createFormWithSelectData : endUserAssignDeviceRegistrationsForm}
          setData={handleChange}
          setBool={handleBool}
          setSelect={handleSelect}
          setDate={setDate}
          close={closeModal}
          data={selectedData}
          labels={deviceRegistrationKey.dataMap}
          submit={createDeviceRegistrationSubmit}
          errorMessage={errorMessage}
          title={"Assign Device Registration"}
        />
      )}
      {editingRegistration && (
        <CreateEditModal 
          form={isPrivilegedUser() ? editDeviceRegistrationsForm : endUserEditDeviceRegistrationsForm}
          setData={handleChange}
          setBool={handleBool}
          setDate={setDate}
          setSelect={handleSelect}
          close={closeModal}
          data={selectedData}
          labels={deviceRegistrationKey.dataMap}
          submit={updateDeviceRegistrationSubmit}
          errorMessage={errorMessage}
          title={"Edit Device Registration"}
        />
      )}
      {viewingRegistrationDetail && (
        <CreateEditModal 
          form={isPrivilegedUser() ? detailDeviceRegistrationsForm : endUserDetailDeviceRegistrationsForm}
          close={closeModal}
          data={selectedData}
          labels={deviceRegistrationKey.dataMap}
          errorMessage={errorMessage}
          hideSubmit={true}
          title={"Device Registration Detail"}
        />
      )}
    </div>
  )
}