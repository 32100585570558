import React, { useState } from "react";
import Loader from "../components/Loader";
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';
import { formatDateTime } from "../utils/dateTime"
import AreYouSureModal from './AreYouSureModal';
import "react-datepicker/dist/react-datepicker.css";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function CreateEditModal(props) {
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
    const [dataHasBeenAltered, setDataHasBeenAltered] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const closeExitModal = () => {
        if (dataHasBeenAltered) {
            setShowAreYouSureModal(true);
        } else {
            props.close();
        }
    }

    const clickSubmit = async () => {
        setSubmitting(true);
        if (!submitting) {
            await props.submit();
            setSubmitting(false);
        }
    }

    const dataCallback = (e, record, type) => {
        setDataHasBeenAltered(true);
        switch(type) {
          case "date":
            props.setDate(e, record.name);
            break;
          case "bool":
            props.setBool(record.name, !props.data[record.name]);
            break;
          case "select":
            props.setSelect(e);
            break;
          case "autocomplete":
            props.setAutocomplete(e);
            break;
          case "multiautocomplete":
            props.setAutocomplete(e);
            break;
          default:
            props.setData(e);
        }

    }

    const formFields = () => {
        return props.form.map((record) => {
            let selectedDate = new Date();
            if (record.type === "datepicker") {
                selectedDate = props.data[record.name] ? new Date(props.data[record.name]) : props.data[record.name];
            }
            switch (record.type) {
                case "text":
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <input onChange={(e) => dataCallback(e, record, "text")} autoFocus={record.isAutoFocus} maxLength={record.maxLength} type="text" value={props.data[record.name]} name={record.name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder={record.placeholder} required={record.required} />
                        </div>
                    )
                case "immutable":
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <p className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                {props.data[record.name] ? props.data[record.name] : "null"}
                            </p>
                        </div>
                    )
                case "immutableBool":
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <p className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                {props.data[record.name] ? "True" : "False"}
                            </p>
                        </div>
                    )
                case "dateTime":
                  return (
                    <div className="col-span-1">
                        <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                        <p className="px-2 py-2.5 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-lg w-full p-2.5">{formatDateTime(props.data[record.name])}</p>
                    </div>
                  )
                case "isActive": {
                    let trueText = "Active";
                    let falseText = "Disabled";
                    if (record.useBoolText) {
                        trueText = "True";
                        falseText = "False";
                    }
                    return (
                        <div className="col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <Switch.Group as="div" className="flex items-center mt-4">
                                <Switch
                                    checked={props.data[record.name]}
                                    onChange={(e) => dataCallback(e, record, "bool")}
                                    className={classNames(
                                        props.data[record.name] ? 'focus:ring-emerald-600 bg-emerald-400' : 'focus:ring-red-400 bg-red-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            props.data[record.name] ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                        )}
                                    />
                                </Switch>
                                <Switch.Label as="span" className="ml-3">
                                    <span className="text-md font-medium text-gray-600 font-semibold">{props.data[record.name] ? trueText : falseText}</span>
                                </Switch.Label>
                            </Switch.Group>
                        </div>
                    )
                }
                case "datepicker":
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5" >
                                <DatePicker
                                    name={record.name}
                                    selected={selectedDate}
                                    placeholderText={new Date(Date.now()).toLocaleDateString('en-US')}
                                    onChange={(e) => dataCallback(e, record, "date")}
                                    minDate={Date.now()}
                                    dateFormat="MM/dd/yyyy"
                                />
                            </div>
                        </div>
                    )
                case "autocomplete":
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <AsyncSelect loadOptions={record.options}
                                         onChange={(e) => dataCallback(e, record, "autocomplete")}
                                         name={record.name}
                                         required={record.required} />
                        </div>
                    )
                case "multiautocomplete":
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                            <AsyncSelect loadOptions={record.options}
                                         value={props.data[record.name]}
                                         placeholder="Start typing to search..."
                                         onChange={(e) => dataCallback(e, record, "autocomplete")}
                                         name={record.name}
                                         isMulti
                                         required={record.required} />
                        </div>
                    )
                case "select": {
                    let showField = true;
                    if (record.canShowField) {
                      showField = props.canShowField(props.data);
                    }
                    if (showField) {
                        return (
                            <div className="col-span-1">
                                <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{record.label}</label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <select
                                        name={record.name}
                                        onChange={(e) => dataCallback(e, record, "select")}
                                        value={props.data[record.name]}
                                        className="block w-full pr-10 pl-2.5 py-2 text-base font-normal text-gray-700 bg-gray-50 border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-emerald-600 focus:outline-none"
                                    >
                                        <option value={'default'}>{record.selectName}</option>
                                        {record.options.map((item, index) => {
                                            return <option key={index} value={item} className="border border-solid border-gray-300 rounded-lg block w-full">{item}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        )
                    } else {
                        return (<div></div>);
                    }

                }
                
                default:
                    return (
                        <div className="col-span-1">
                            <label htmlFor={record.name} className="block mb-2 text-sm font-medium text-gray-900">{props.labels[record.name].column}</label>
                            <input onChange={(e) => dataCallback(e, record, "text")} type="text" value={props.data[record.name]} name={record.name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder={record.placeholder} required={record.required} />
                        </div>
                    )
            }
        })
    }

    if (showAreYouSureModal) {
        return (
            <AreYouSureModal isOpen={showAreYouSureModal} yes={props.close} no={() => setShowAreYouSureModal(false)} />
        )
    } else {
        return (
            <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full mt-20 md:inset-0 h-modal md:h-full">
                <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <div className="relative p-4 md:w-full w-1/2 md:max-w-4xl md:h-full md:h-auto mx-auto">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex justify-end p-2">
                            <button onClick={() => closeExitModal()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="authentication-modal">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>
                        <form className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8" action="#">
                            <h3 className="text-xl font-medium text-gray-900">{props.title}</h3>
                            {props.errorMessage && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                    <strong className="font-bold">Error: </strong>
                                    <span className="block sm:inline">{props.errorMessage}</span>
                                </div>
                            )}
                            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                                {formFields()}
                            </div>
                            {!props.hideSubmit && (
                                <button
                                    type="button"
                                    onClick={clickSubmit}
                                    className={`${submitting ? "bg-emerald-600" : "bg-emerald-500"} w-full text-white hover:bg-emerald-600 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                                >
                                    {submitting && (
                                    <div className="flex items-center justify-center mx-auto">
                                        <Loader height={"h-6"} width={"w-6"} />
                                    </div>
                                    )}
                                    {!submitting && (
                                        <div>
                                            Submit
                                        </div>
                                    )}
                                </button>
                            ) }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

CreateEditModal.propTypes = {
    submit: PropTypes.func,
    data: PropTypes.object,
    errorMessage: PropTypes.string,
    setData: PropTypes.func,
    setBool: PropTypes.func,
    setDate: PropTypes.func,
    setSelect: PropTypes.func,
    setAutocomplete: PropTypes.func,
    title: PropTypes.string,
    form: PropTypes.array,
    labels: PropTypes.object,
    hideSubmit: PropTypes.bool,
    canShowField: PropTypes.func,
    close: PropTypes.func,
}