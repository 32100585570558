import { INTERNAL_SERVER_ERROR, FORBIDDEN } from "./constants";

export function formatErrorResponse(response) {
  if (response.status == 400) {
    if (response.data.message) {
      return { error: response.data.message }
    }
    const errorKeys = Object.keys(response.data.errors);
    const errorList = errorKeys.map(errorField => response.data.errors[errorField][0]);
    const errorMessages = errorList.join(", ");
    return { error: errorMessages }
  } else if (response.status === 500) {
    return { error: INTERNAL_SERVER_ERROR }
  } else if (response.status === 403) {
    return { error: FORBIDDEN}
  } else if (response.status === 404) {
    if (response.data?.message) {
      return { error: response.data.message }
    }
    return { error: "An error occurred" }
  } 
  else if (response.status == 422) {
    return {error: "Unable to create record. Make sure all Frontier objects exist to create record."}
  }
  else {
    return { error: response.data.message }
  }
}