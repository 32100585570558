
import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const createSelfCheckoutUser = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/SelfCheckout/User', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }

}

export const validateSelfCheckoutUser = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/SelfCheckout/ValidateUser', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}