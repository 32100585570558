import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getLocations = async () => {

  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/Location', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

