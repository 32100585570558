import React, { useState, useEffect } from "react";

import { getUserList, createUser, updateUser } from '../services/Users';
import CreateEditModal from "../components/CreateEditModal";
import { getCompaniesBySearch, getCompanyList } from '../services/Companies';
import Loader from "../components/Loader";
import { createUserForm, editUserForm, detailUserForm } from "../utils/forms";
import { usersKey, mobileUsersKey } from "../utils/labelMap";
import DynamicTable from "../components/DynamicTable";


export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [viewingUserDetail, setViewingUserDetail] = useState(false);
  const [createFormWithSelectData, setCreateFormWithSelectData] = useState([]);
  const [editFormWithSelectData, setEditFormWithSelectData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    async function fetchData() {
        setLoading(true)
        const data = await getUserList();
        if (data.error) {
          setPageErrorMessage(data.error);
          setLoading(false)
        } else {

          setUsers(data);
          setPageErrorMessage(false);
          setLoading(false)
        }
    }
    fetchData();

    async function fetchCompanies() {
      setLoading(true);
        const companies = await getCompanyList();
        if (companies.error) {
          setErrorMessage(companies.error);
        } else if (companies.length > 0) {
          addDataToSelectOptions();
        }
        setLoading(false);
    }
    fetchCompanies();
  }, []);

  const addDataToSelectOptions = () => {
    let createFormCopy = [...createUserForm];
    let keyIndex = createFormCopy.findIndex(f => f.name === "companyIds");
    createFormCopy[keyIndex].options = promiseOptions;
    setCreateFormWithSelectData(createFormCopy);

    let editFormCopy = [...editUserForm];
    keyIndex = editFormCopy.findIndex(f => f.name === "companyIds");
    editFormCopy[keyIndex].options = promiseOptions;
    setEditFormWithSelectData(editFormCopy);
  }

  const openEditUserModal = (e, data) => {
    e.stopPropagation();
    setEditing(true);
    data.companyIds = data.companies.map(c => ({value: c.id, label: c.name}))
    setSelectedData(data);
  }

  const openCreateUserModal = () => {
    setSelectedData({});
    setCreating(true);
  }

  const openUserDetailModal = (e, data) => {
    e.stopPropagation(); 
    setViewingUserDetail(true);
    data.companyIds = data.companies.map(c => c.name).join(", ");
    setSelectedData(data);
  }

  const closeModal = () => {
    setEditing(false);
    setCreating(false);
    setViewingUserDetail(false);
    setErrorMessage('');
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  const filterCompanies = async (inputValue) => {
    let companies = await getCompaniesBySearch(inputValue);
    return companies.map(c => ({ value: c.id, label: c.name }));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterCompanies(inputValue));
      }, 1000);
    });

  const updateUserSubmit = async () => {
    setErrorMessage('');
    if (selectedData.companyIds.length === 0)  {
      setErrorMessage("Please select a Company");
      return;
    }
    let body = selectedData;
    body.companyIds = selectedData.companyIds.map(e => e.value)
    let response = await updateUser(body);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setEditing(false);

    response.companyIds = response.companies.map(c => ({ value: c.id, label: c.name }));
    setUsers(users.map(user => user.id === response.id ? response : user));
  }

  const createUserSubmit = async () => {
    setErrorMessage('');
    if (selectedData.companyIds.length === 0)  {
      setErrorMessage("Please select a Company");
      return;
    }
    let body = selectedData;
    body.companyIds = selectedData.companyIds.map(e => e.value)
    const response = await createUser(body);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreating(false);

    const newUsers = [...users, response];
    setUsers(newUsers);
  }

  const activateDeactivateUser = async (user) => {
    setErrorMessage('');
    let newUser = { ...user};
    newUser.companyIds = newUser.companies.map(e => e.id);
    newUser.isActive = !newUser.isActive;
    const response = await updateUser(newUser);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setUsers(users.map(user => user.id === response.id ? response : user));
  }

  const handleAutocomplete = (e) => {
    let newCompanyIds = e;
    setSelectedData(selectedData => ({
      ...selectedData,
      companyIds: newCompanyIds
    }));
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      {loading && (
        <Loader />
      )}
      {pageErrorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{pageErrorMessage}</span>
        </div>
      )}
      {!pageErrorMessage && (
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Users</h1>
              <p className="mt-2 text-sm text-gray-700">A list of all Cloud9 Retail users.</p>
            </div>
            <div className="sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => openCreateUserModal()}
              >
                Add User
              </button>
            </div>
          </div>
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <DynamicTable
          rowData={users}
          legend={usersKey}
          loading={loading}
          activateDeactivateRow={activateDeactivateUser}
          openEditRowModal={openEditUserModal}
          openDetailRowModal={openUserDetailModal}
          mobileLegend={mobileUsersKey}
          allowExpandOptions={true}
          hasChildData={false}
          noDataMessage={"No Users have been added"}
        />
      </div>
      )}
      {creating && (
        <CreateEditModal 
          form={createFormWithSelectData}
          setData={handleChange}
          close={closeModal}
          setSelect={null}
          setBool={handleBool}
          data={selectedData}
          labels={usersKey.dataMap}
          submit={createUserSubmit}
          setAutocomplete={handleAutocomplete}
          errorMessage={errorMessage}
          title={"Create User"}
        />
      )}
      {editing && (
        <CreateEditModal 
          form={editFormWithSelectData}
          setData={handleChange}
          close={closeModal}
          setSelect={null}
          setBool={handleBool}
          data={selectedData}
          labels={usersKey.dataMap}
          setAutocomplete={handleAutocomplete}
          submit={updateUserSubmit}
          errorMessage={errorMessage}
          title={"Edit User"}
        />
      )}
      {viewingUserDetail && (
        <CreateEditModal 
          form={detailUserForm}
          close={closeModal}
          data={selectedData}
          labels={usersKey.dataMap}
          errorMessage={errorMessage}
          hideSubmit={true}
          title={"User Detail"}
        />
      )}
    </div>
  )
}