import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';
import { INTERNAL_SERVER_ERROR } from '../utils/constants';

export const getCompanyProfile = async (user) => {
  const config = {
    withCredentials: true
  };
  let payload = {
    userId: user.id,
    email: user.email
  };
  try {
    const response = await axios.get('/Company/company-profile', payload, config);
    return response.data;
  } catch (error) {
    if (error.response.status === 500) {
      return { error: INTERNAL_SERVER_ERROR }
    } else {
      return { error: error }
    }
  }
}

export const getCompanyList = async () => {
  const config = {
    withCredentials: true
  };
  try {
    const response = await axios.get('/Company', config);
    return response.data;
  } catch (error) {
    if (error.response.status === 500) {
      return { error: INTERNAL_SERVER_ERROR }
    } else {
      return { error: error }
    }
  }
}

export const updateCompany = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/Company', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createCompany = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/Company', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const getCompaniesBySearch = async (searchTerm) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get(`/Company/Search/${searchTerm}`, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}