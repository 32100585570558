import React from "react";
import PropTypes from 'prop-types';

export default function MobileCard(props) {

    const childDataExists = () => {
        return props.data[props.childField] && props.data[props.childField].length > 0; 
    }

    const copyClipboard = (e, text) => {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
    }

    return (
        <div className="space-y-2 p-4 rounded-lg shadow cursor-pointer hover:bg-green-100" onClick={(e) => props.openDetailRowModal(e, props.data)}>
            {props.keys.map((fieldId) => {
                let column = props.legend[fieldId].column;
                let value = props.data[fieldId];
                return (
                    <div key={fieldId}>
                        <div key={fieldId} className="flex items-center space-x-2 font-medium text-sm">
                            <div>
                                {column}
                            </div>
                        </div>
                        <div>
                            <div key={fieldId} className="text-sm ml-2 text-gray-500 font-semibold">
                                {value}
                                {props.legend[fieldId].hasClipboard && (
                                    <button
                                        className={"cursor-grab ml-4 items-center"}
                                        onClick={(e) => copyClipboard(e, value)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 items-center justify-center content-center">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                                        </svg>
                                    </button>
                                                    )}
                            </div>
                        </div>
                    </div>
                )
            })}
            {childDataExists() && (
              <div>
                <div className="flex items-center space-x-2 font-bold text-sm text-indigo-700">Options</div>
                    {props.childKeys.map((childKey) => {
                        return (
                            <div key={childKey}>
                                {props.data[props.childField].map((child) => {
                                    let childRef = props.childLegend[childKey];
                                    let childValue = child[childKey];
                                    if (childRef.type === 'parent') {
                                        childValue = props.getParentValue(child);
                                    } 

                                    return (
                                        <div key={childKey}>
                                            <div key={childKey} className="flex ml-2 items-center space-x-2 font-medium text-sm">
                                                <div>
                                                    {childRef.column}
                                                </div>
                                            </div>
                                            <div>
                                                <div key={childKey} className="text-sm ml-4 text-gray-500 font-semibold">
                                                    {childValue}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )}
            <button
                className="px-4 rounded text-sm bg-indigo-700"
                onClick={(e) => props.openEditRowModal(e, props.data)}
            >
                <p className="text-white font-semibold">Edit</p>
            </button>
            {props.resetSubmit && (
                <div>
                    <button
                        className="px-4 rounded text-sm bg-red-400"
                        onClick={(e) => props.resetSubmit(e, props.data)}
                    >
                        <p className="text-white font-semibold">Reset</p>
                    </button>
                </div>
            )}
        </div>
    )
}

MobileCard.propTypes = {
    legend: PropTypes.object,
    data: PropTypes.object,
    childLegend: PropTypes.object,
    childKeys: PropTypes.array,
    childField: PropTypes.string,
    keys: PropTypes.array,
    parentIndex: PropTypes.number,
    openEditRowModal: PropTypes.func,
    openDetailRowModal: PropTypes.func,
    resetSubmit: PropTypes.func,
    getParentValue: PropTypes.func
}