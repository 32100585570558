import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import axios from 'axios';
import PropTypes from 'prop-types';

import { Login } from "./Login";
import TopNavigation from "../components/TopNavigation";
import RequestInterceptor from "../RequestInterceptor";
import { config } from '../utils/constants';
import SideNavigation from "../components/SideNavigation";

import { formatErrorResponse } from '../utils/formatErrors';
import { CompanySelect } from "./CompanySelect";

export const PageLayout = (props) => {

    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    const [sideBarClosed, setSideBarClosed] = useState(false);
    const [activeUser, setActiveUser] = useState(JSON.parse(sessionStorage.getItem("user")));
    const [selectedCompany, setSelectedCompany] = useState(sessionStorage.getItem("company") ?? 0);
    const [companyIsSet, setCompanyIsSet] = useState(false);
    
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 1900) {
                setSideBarClosed(false);
            }
            else if (window.innerWidth > 1900) {
                setSideBarClosed(false);
            }
        }
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        async function fetchData() {
            var email = activeAccount.idTokenClaims.email;
            try {
                const response = await axios.get(`/User/ByEmail/${email}`, config);
                setUser(response.data);
            } catch (error) {
                return formatErrorResponse(error.response);
            }

        }
        if (inProgress === InteractionStatus.None && isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated])

    useEffect(() => {
        if(!companyIsSet && activeUser && activeUser.companies && activeUser.companies.length == 1) {
            setUserWithCompany(activeUser.companies[0].id);
        }
    }, [activeUser])

    let setUser = (user) => {
        if (user) {
            sessionStorage.setItem("user", JSON.stringify(user));
            setActiveUser(user);
        }
    }

    let setUserWithCompany = (companyId) => {
        async function fetchData() {
            var email = activeAccount.idTokenClaims.email;
            try {
                const response = await axios.get(`/User/ByEmail/${companyId}/${email}`, config);
                setUser(response.data);
                var comp = activeUser.companies.find((company) => company.id == companyId);
                setSelectedCompany(comp);
                sessionStorage.setItem("company", JSON.stringify(comp))
                setCompanyIsSet(true);
            } catch (error) {
                return formatErrorResponse(error.response);
            }

        }
        fetchData();
    }

    function handleChange(newValue) {
        setSideBarClosed(newValue);
    }

    return (
        <>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
            <br />
            <AuthenticatedTemplate>
            <RequestInterceptor>
                <div>
                    {!selectedCompany ? 
                        <CompanySelect
                          company={selectedCompany}
                          setUserWithCompany={setUserWithCompany}
                          user={activeUser} />
                    :
                        <div>
                            <TopNavigation />
                            <div className="flex">
                                <div className="min-h-screen shadow-md mr-2">
                                    <SideNavigation closed={sideBarClosed} handleClick={handleChange} hasViewUserPermissions={activeUser?.permissions?.includes("SystemUserRead")} />
                                </div>
                                    {props.children}
                            </div>
                        </div>
                    }
                </div>
                </RequestInterceptor>

            </AuthenticatedTemplate>
        </>
    );
}


PageLayout.propTypes = {
    children: PropTypes.object
}