import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';


export const getUserList = async () => {
  const config = {
    withCredentials: true
  };
  try {
    const response = await axios.get('/User', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const deactivateUser = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put(`/User/Deactivate/${data.id}`, data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateUser = async (data) => {
  const config = {
    withCredentials: true
  };
  try {
    const response = await axios.put('/User', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const createUser = async (data) => {
  data.frontendURL = window.location.origin;
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.post('/User', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}
