export const AUTH_ENROLLMENT_TYPE = "AUTHORIZATION";
export const EMAIL_ENROLLMENT_TYPE = "EMAIL";

export const COMPLETED = "COMPLETED";
export const IN_PROCESS = "IN_PROCESS";
export const NOT_STARTED = "NOT_STARTED";

export const RENDER_LOGIN = "RENDER_LOGIN";
export const RENDER_OTP_CHALLENGE = "RENDER_OTP_CHALLENGE";
export const RENDER_ENROLLMENT = "RENDER_ENROLLMENT";
export const RENDER_RESET_PASSWORD = "RENDER_RESET_PASSWORD";
export const RENDER_DASHBOARD = "RENDER_DASHBOARD";

// permission constants

export const SYSTEM_PERMISSIONS = ["SystemUserRead"];

export const PERMISSIONS = {
    ManageCompany: 'ManageCompany',
    PointOfSale: 'PointOfSale',
    ManageInventory: 'ManageInventory',
    ReadOnly: 'ReadOnly',
    Manager: 'Manager'
};

export const PAGE_PERMISSIONS = {
    Company: [PERMISSIONS.ManageCompany, PERMISSIONS.ManageInventory]
};

export const VALIDATION_TYPES = {
    NONE: 'none',
};

export const INTERNAL_SERVER_ERROR = "Something went wrong. Please contact Cloud9 Retail";
export const FORBIDDEN = "Forbidden. You are not allowed to perform this action";

const c = {
    API_URL: process.env.REACT_APP_API_URL
}

export const config = c;
