
import React, { useState, useEffect } from "react";

//import { getCompanyList, updateCompany, createCompany } from '../services/Companies';
import { createSelfCheckoutUser, validateSelfCheckoutUser } from '../services/SelfCheckout';
import CreateEditModal from "../components/CreateEditModal";
//import Loader from "../components/Loader";
import { selfCheckoutUserForm } from "../utils/forms";
import { selfCheckoutUserKey } from "../utils/labelMap";
//import DynamicTable from "../components/DynamicTable";


export default function SelfCheckout() {
  //const [companies, setCompanies] = useState([]);
  //const [loading, setLoading] = useState(true);
  //const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  //const [viewingCompanyDetail, setViewingCompanyDetail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageErrorMessage] = useState('');
  const [selectedData, setSelectedData] = useState([]);

  //let user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {});
  //useEffect(() => {
  //  async function fetchData() {
  //      setLoading(true)
  //      const data = await getCompanyList(user);
  //      if (data.error) {
  //        setPageErrorMessage(data.error);
  //        setLoading(false)
  //      } else {
  //        setCompanies(data);
  //        setPageErrorMessage(false);
  //        setLoading(false)
  //      }
  //  }
  //  fetchData();
  //}, []);

  //const openEditCompanyModal = (e, data) => {
  //  e.stopPropagation();
  //  setEditing(true);
  //  setSelectedData(data);
  //}

  const openCreateSelfCheckoutUserModal = () => {
    setSelectedData({});
    setCreating(true);
  }

  //const openCompanyDetailModal = (e, data) => {
  //  e.stopPropagation(); // stops registration options from rendering when Row is clicked
  //  setViewingCompanyDetail(true);
  //  setSelectedData(data);
  //}

  const closeModal = () => {
    //setEditing(false);
    setCreating(false);
    //setViewingCompanyDetail(false);
    setErrorMessage('');
  }

  const handleBool = (name, boolValue) => {
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: boolValue
    }));
  };

  const handleChange = e => {
    let { name, value } = e.target;
    setSelectedData(selectedData => ({
      ...selectedData,
      [name]: value
    }));
  };

  //const updateCompaniesSubmit = async () => {
  //  setErrorMessage('');
  //  const response = await updateCompany(selectedData);
  //  if (response.error) {
  //    setErrorMessage(response.error);
  //    return;
  //  }
  //  setEditing(false);
  //  setCompanies(companies.map(company => company.id === response.id ? response : company));
  //}

  const createSelfCheckoutUserSubmit = async () => {
    setErrorMessage('');
    const response = await createSelfCheckoutUser(selectedData);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    setCreating(false);
    //const newCompanies = [...companies, response];
    //setCompanies(newCompanies);
  }

  const validateSelfCheckoutUserSubmit = async () => {
    setErrorMessage('');
    let request = {
      "email": "pbednar@cksystem.com",
      "userId": "6431",
      "password": "1101"
    }
    //var data = JSON.stringify(request);
    const response = await validateSelfCheckoutUser(request);
    if (response.error) {
      setErrorMessage(response.error);
      return;
    }
    //const newCompanies = [...companies, response];
    //setCompanies(newCompanies);
  }

  //const activateDeactivateCompany = async (company) => {
  //  setErrorMessage('');
  //  let newCompany = { ...company };
  //  newCompany.isActive = !newCompany.isActive;
  //  const response = await updateCompany(newCompany);
  //  if (response.error) {
  //    setErrorMessage(response.error);
  //    return;
  //  }
  //  setCompanies(companies.map(company => company.id === response.id ? response : company));
  //}

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-20">
      {pageErrorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{pageErrorMessage}</span>
        </div>
      )}
      {!pageErrorMessage && (
        <div>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Self Checkout</h1>
              <p className="mt-2 text-sm text-gray-700">Self Checkout modules</p>
            </div>
            <div className="sm:mt-0 mt-4 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => openCreateSelfCheckoutUserModal()}
              >
                Add User
              </button>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => validateSelfCheckoutUserSubmit()}
              >
                Validate User
              </button>
            </div>
          </div>
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        {creating && (
          <CreateEditModal 
            form={selfCheckoutUserForm}
            setData={handleChange}
            close={closeModal}
            setBool={handleBool}
            data={selectedData}
            labels={selfCheckoutUserKey.dataMap}
            submit={createSelfCheckoutUserSubmit}
            errorMessage={errorMessage}
            title={"Create User"}
          />
        )}
      </div>
      )}
    </div>
  )
}
      //{loading && (
      //  <Loader />
      //)}
        //<DynamicTable
        //  rowData={companies}
        //  legend={companiesKey}
        //  loading={loading}
        //  hasChildData={false}
        //  activateDeactivateRow={activateDeactivateCompany}
        //  openEditRowModal={openEditCompanyModal}
        //  openDetailRowModal={openCompanyDetailModal}
        //  openCreateChildModal={activateDeactivateCompany}
        //  activateDeactivateChild={activateDeactivateCompany}
        //  openEditChildModal={activateDeactivateCompany}
        //  mobileLegend={mobileCompaniesKey}
        //  noDataMessage={"No Companies have been added"}
        //  allowExpandOptions={true}
        ///>
        //{editing && (
        //  <CreateEditModal 
        //    form={companiesForm}
        //    setData={handleChange}
        //    close={closeModal}
        //    setBool={handleBool}
        //    data={selectedData}
        //    labels={companiesKey.dataMap}
        //    submit={updateCompaniesSubmit}
        //    errorMessage={errorMessage}
        //    title={"Edit Company"}
        //  />
        //)}
        //{viewingCompanyDetail && (
        //  <CreateEditModal 
        //    form={detailCompaniesForm}
        //    close={closeModal}
        //    data={selectedData}
        //    labels={companiesKey.dataMap}
        //    errorMessage={errorMessage}
        //    hideSubmit={true}
        //    title={"Company Detail"}
        //  />
        //)}