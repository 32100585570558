import axios from 'axios';
import { formatErrorResponse } from '../utils/formatErrors';

export const getDeviceRegistrationList = async () => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.get('/DeviceRegistration', config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const updateDeviceRegistration = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/DeviceRegistration', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const assignDeviceRegistration = async (data) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put('/DeviceRegistration/Assign', data, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}

export const resetDeviceRegistration = async (deviceId) => {
  const config = {
    withCredentials: true
  };

  try {
    const response = await axios.put(`/DeviceRegistration/Reset/${deviceId}`, {}, config);
    return response.data;
  } catch (error) {
    return formatErrorResponse(error.response);
  }
}