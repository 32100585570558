import React from "react";
import PropTypes from "prop-types";

export default function Loader(props) {

  const divStyle = {
    borderTopColor: "transparent"
  };

  if (props.width || props.height) {
    return (
      <div style={divStyle} className={`${props.width} ${props.height} border-4 border-blue-400 border-solid rounded-full animate-spin`}></div>
    )
  } else {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
        <div style={divStyle} className="w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
      </div>
    )
  }
}

export function TdLoader() {

  const divStyle = {
    borderTopColor: "transparent"
  };

  return (
    <td className="whitespace-nowrap px-3 py-4 text-sm text-indigo-700 hover:text-indigo-900 font-semibold overflow-hidden flex flex-col items-center justify-center">
      <div style={divStyle} className="w-6 h-6 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
    </td>
  )
}

Loader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}